import * as React from "react";
import { sbEditable } from "@storyblok/storyblok-editable";

import PropTypes from "lib/propTypes";
import ExpandingFlex from "./layout/ExpandingFlex";

const sections = {};

function DynamicSection({ blok, ...props }) {
  if (!blok.component) return null;

  if (typeof sections[blok.component] === `undefined`)
    return (
      <p>
        The component <strong>{blok.component}</strong> has not been created
        yet.
      </p>
    );

  const Component = sections[blok.component];
  return (
    <ExpandingFlex {...sbEditable(blok)}>
      <Component blok={blok} {...props} />
    </ExpandingFlex>
  );
}

DynamicSection.propTypes = {
  blok: PropTypes.blok.isRequired,
};

export default DynamicSection;

export const registerSection = (name, Component) => {
  sections[name] = Component;
};
