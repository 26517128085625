import * as React from "react";

import VideoComponent from "components/media/Video";
import { registerSection } from "components/DynamicSection";
import PropTypes from "lib/propTypes";

function Video({
  blok: {
    autoPlay,
    backgroundColor,
    controls,
    loop,
    mobileSources,
    muted,
    poster,
    sources,
  },
}) {
  return (
    <VideoComponent
      playsInline={autoPlay}
      poster={poster?.filename}
      {...{
        autoPlay,
        backgroundColor,
        controls,
        loop,
        mobileSources,
        muted,
        sources,
      }}
    />
  );
}

Video.propTypes = {
  blok: PropTypes.shape({
    autoPlay: PropTypes.bool,
    backgroundColor: PropTypes.color,
    controls: PropTypes.oneOf([``, `all`, `unmute`]),
    loop: PropTypes.bool,
    mobileSources: PropTypes.arrayOf(PropTypes.asset),
    muted: PropTypes.bool,
    poster: PropTypes.asset,
    sources: PropTypes.arrayOf(PropTypes.asset),
  }).isRequired,
};

registerSection(`Video`, Video);

export default Video;
