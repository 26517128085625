import * as React from "react";
import { Fragment } from "react";
import reactStringReplace from "react-string-replace";

import wowKerning from "./wowKerning";

export default (text) =>
  reactStringReplace(text, / +(\S+)$/, (match, index) => (
    <Fragment key={`orphan-${index}`}>
      &nbsp;{wowKerning(match, `-.1em`)}
    </Fragment>
  ));
