module.exports = {
  xxxs: 10,
  xxs: 15,
  xs: 20,
  s: 25,
  m: 30,
  l: 35,
  xl: 40,
  xxl: 45,
  xxxl: 50,
};
