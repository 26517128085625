import * as React from "react";

import Container from "components/layout/Container";
import Wrapper from "components/layout/Wrapper";
import DynamicSection, { registerSection } from "components/DynamicSection";
import PropTypes from "lib/propTypes";

const ComponentOverrides = {
  Heading: (props) => <DynamicSection my={[`s`, `l`]} {...props} />,
  Text: (props) => <DynamicSection sx={{ p: { my: [`s`, `l`] } }} {...props} />,
};

function TextContainer({ blok: { backgroundColor, body, color, thin } }) {
  return (
    <Wrapper
      bg={backgroundColor}
      color={color}
      className={color === `white` ? `light` : `dark`}
    >
      <Container thin={thin} sx={{ "> div": { display: `block` } }}>
        {body.map(({ _uid, ...blok }) => {
          const Component =
            ComponentOverrides[blok.component] ?? DynamicSection;
          return <Component key={_uid} blok={{ _uid, ...blok }} standalone />;
        })}
      </Container>
    </Wrapper>
  );
}

TextContainer.propTypes = {
  blok: PropTypes.shape({
    backgroundColor: PropTypes.color,
    body: PropTypes.arrayOf(PropTypes.blok),
    color: PropTypes.oneOf([`black`, `white`]),
    thin: PropTypes.bool,
  }).isRequired,
};

registerSection(`TextContainer`, TextContainer);

export default TextContainer;
