import * as React from "react";
import { Box, Flex } from "theme-ui";

import Link from "components/Link";
import PropTypes from "lib/propTypes";

function AdditionalLinks({ links }) {
  return (
    <Flex as="ul" variant="lists.unstyled" mr="xxs">
      {links.map(({ _uid, link, title }) => (
        <Box key={_uid} as="li" mr="xxs">
          <Link variant="footer" to={link}>
            {title}
          </Link>
        </Box>
      ))}
    </Flex>
  );
}

AdditionalLinks.propTypes = {
  links: PropTypes.arrayOf(PropTypes.link),
};

AdditionalLinks.defaultProps = {
  links: [],
};

export default AdditionalLinks;
