module.exports = {
  "light-primary": {
    variant: `buttons.brand-primary`,
    borderColor: `lightGray`,
    bg: `lightGray`,
    color: `black`,
    "&:focus-visible, &:hover": {
      borderColor: `white`,
      bg: `white`,
      color: `black`,
    },
    "&:active": {
      borderColor: `gray`,
      bg: `gray`,
      color: `nearBlack`,
    },
  },
  "light-secondary": {
    variant: `buttons.brand-secondary`,
    color: `lightGray`,
    "&:focus-visible, &:hover": {
      color: `white`,
    },
    "&:active": {
      color: `gray`,
    },
  },
  "light-tertiary": {
    variant: `buttons.brand-tertiary`,
    color: `lightGray`,
    "&:focus-visible, &:hover": {
      color: `white`,
    },
    "&:active": {
      color: `gray`,
    },
  },
};
