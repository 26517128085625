const buttons = require(`./buttons`);
const headings = require(`./headings`);
const links = require(`./links`);
const scales = require(`./scales`);
const styles = require(`./styles`);
const text = require(`./text`);

module.exports = {
  breakpoints: [`40em`, `52em`, `64em`, `80em`],
  ...scales,
  layout: {
    container: {
      px: [35, 35, 16],
    },
    thin: {
      variant: `layout.container`,
      maxWidth: `thinContainer`,
    },
  },
  styles,
  grids: {
    default: {
      gridTemplateColumns: `repeat(6, 1fr)`,
      gridGap: [`xxxs`, `xxxs`, `xxs`],
    },
  },
  headings,
  text,
  links,
  buttons,
  boxes: {
    footer: {
      background: `center/cover no-repeat`,
      bg: `black`,
      color: `white`,
    },
  },
  lists: {
    unstyled: {
      pl: 0,
      listStyle: `none`,
    },
  },
  cards: {
    primary: {
      py: 20,
      borderTop: `1px solid`,
    },
  },
};
