import * as React from "react";
import { useEffect } from "react";
import loadable from "@loadable/component";
import { useLocation } from "@gatsbyjs/reach-router";
import { useToggle } from "react-use";

import PropTypes from "lib/propTypes";
import useTransparentHeader from "lib/useTransparentHeader";
import Bar from "./Bar";

const Navigation = loadable(() => import(`./Navigation`));

function Header({ logo, logoIcon, logoText, navigation }) {
  const [showMenu, toggleShowMenu] = useToggle(false);

  const currentUrl = useLocation();
  useEffect(() => toggleShowMenu(false), [currentUrl, toggleShowMenu]);

  const [transparentHeader] = useTransparentHeader();

  const isHome = currentUrl.pathname === `/`;

  return (
    <header>
      <Bar
        iconMode={transparentHeader ? `light` : `dark`}
        logo={isHome ? null : logo}
        logoIcon={isHome ? null : logoIcon}
        logoText={logoText}
        toggle={toggleShowMenu}
        sx={{
          position: `absolute`,
          top: 0,
          right: 0,
          left: 0,
          zIndex: 1,
        }}
      />
      <Navigation
        close={() => toggleShowMenu(false)}
        visible={showMenu}
        {...{ logo, logoIcon, navigation }}
      />
    </header>
  );
}

Header.propTypes = {
  logo: PropTypes.asset.isRequired,
  logoIcon: PropTypes.asset.isRequired,
  logoText: PropTypes.string,
  navigation: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

Header.defaultProps = {
  logoText: null,
};

export default Header;
