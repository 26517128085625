import * as React from "react";
import { Card as TUICard } from "theme-ui";

import Button from "components/form/Button";
import Heading from "components/typography/Heading";
import Text from "components/typography/Text";
import PropTypes from "lib/propTypes";

function Card({ buttonText, buttonTo, color, heading, subheading }) {
  return (
    <TUICard>
      {heading && (
        <Heading as="h2" variant="h6" mb={10}>
          {heading}
        </Heading>
      )}

      {subheading && (
        <Text as="h3" variant="m" mb={10}>
          {subheading}
        </Text>
      )}

      {buttonText && (
        <Button
          variant={`${color === `white` ? `light` : `dark`}-tertiary`}
          to={buttonTo}
        >
          {buttonText}
        </Button>
      )}
    </TUICard>
  );
}

Card.propTypes = {
  buttonText: PropTypes.string,
  buttonTo: PropTypes.oneOfType([PropTypes.string, PropTypes.link]),
  color: PropTypes.oneOf([`black`, `white`]),
  heading: PropTypes.string,
  subheading: PropTypes.string,
};

Card.defaultProps = {
  buttonText: null,
  buttonTo: null,
  color: `black`,
  heading: null,
  subheading: null,
};

export default Card;
