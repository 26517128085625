import * as React from "react";
import { Flex, Grid } from "theme-ui";

import ConditionalContainer from "components/layout/ConditionalContainer";
import Image from "components/media/Image";
import Text from "components/typography/Text";
import { registerSection } from "components/DynamicSection";
import PropTypes from "lib/propTypes";

const textStyles = {
  pt: 10,
  borderTop: `1px solid`,
  fontWeight: `normal`,
  textTransform: `uppercase`,
  p: { mb: 0 },
  b: {
    display: `inline-block`,
    mr: `.25em`,
    minWidth: `1.75em`,
    textTransform: `none`,
    textAlign: `right`,
  },
};

function Badges({ blok: { badges }, standalone }) {
  return (
    <ConditionalContainer condition={!standalone}>
      <Grid
        as="ul"
        variant="default"
        sx={{
          gridTemplateColumns: `repeat(auto-fit, minmax(6.25rem, 1fr))`,
          pl: 0,
          listStyle: `none`,
        }}
      >
        {badges.map(({ _uid, image, text, title }) => (
          <li key={_uid}>
            {image.filename ? (
              <Flex sx={{ height: 80 }}>
                <Image
                  alt={image.alt}
                  src={image.filename}
                  width="80"
                  height="80"
                  sx={{
                    display: `block`,
                    mx: `auto`,
                    pb: 5,
                    width: `auto`,
                  }}
                />
              </Flex>
            ) : (
              <Text variant="s-bold" sx={{ p: { mb: 10 } }}>
                <p>{title}</p>
              </Text>
            )}
            <Text as="div" variant="caption" sx={textStyles}>
              {text}
            </Text>
          </li>
        ))}
      </Grid>
    </ConditionalContainer>
  );
}

Badges.propTypes = {
  blok: PropTypes.shape({
    badges: PropTypes.arrayOf(
      PropTypes.shape({
        _uid: PropTypes.string,
        image: PropTypes.asset,
        text: PropTypes.richText,
        title: PropTypes.string,
      })
    ),
  }).isRequired,
  standalone: PropTypes.bool,
};

Badges.defaultProps = {
  standalone: false,
};

registerSection(`Badges`, Badges);

export default Badges;
