module.exports = {
  arrow: {
    variant: `buttons.invisible`,
    m: `auto`,
    position: `absolute`,
    top: 0,
    bottom: 0,
    zIndex: 2,
    width: 48,
    height: 48,
    "&:focus-visible, &:hover": {
      color: `mediumGray`,
    },
  },
  "arrow-left": {
    variant: `buttons.arrow`,
    left: 0,
  },
  "arrow-left-light": {
    variant: `buttons.arrow-left`,
    color: `white`,
  },
  "arrow-left-dark": {
    variant: `buttons.arrow-left`,
    color: `black`,
  },
  "arrow-right": {
    variant: `buttons.arrow`,
    right: 0,
  },
  "arrow-right-light": {
    variant: `buttons.arrow-right`,
    color: `white`,
  },
  "arrow-right-dark": {
    variant: `buttons.arrow-right`,
    color: `black`,
  },
};
