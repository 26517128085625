import * as React from "react";
import { Fragment } from "react";
import { Box } from "theme-ui";
import reactStringReplace from "react-string-replace";

export default (text, letterSpacing) =>
  reactStringReplace(text, /(WoW)/g, (match, index) => (
    <Fragment key={`wow-${index}`}>
      <Box as="span" sx={{ letterSpacing: `-.0875em` }}>
        W
      </Box>
      <Box as="span" sx={{ letterSpacing }}>
        o
      </Box>
      W
    </Fragment>
  ));
