import * as React from "react";
import ConditionalWrap from "conditional-wrap";

import Container from "components/layout/Container";
import Wrapper from "components/layout/Wrapper";
import PropTypes from "lib/propTypes";

function WrappedContainer({ children, thin, ...props }) {
  return (
    <Wrapper {...props}>
      <Container thin={thin}>{children}</Container>
    </Wrapper>
  );
}

WrappedContainer.propTypes = {
  children: PropTypes.node,
  thin: PropTypes.bool,
};

WrappedContainer.defaultProps = {
  children: null,
  thin: false,
};

function ConditionalContainer({ children, condition, ...props }) {
  return (
    <ConditionalWrap
      condition={condition}
      wrap={(wrappedChildren) =>
        WrappedContainer({ children: wrappedChildren, ...props })
      }
    >
      {children}
    </ConditionalWrap>
  );
}

ConditionalContainer.propTypes = {
  children: PropTypes.node,
  condition: PropTypes.bool,
};

ConditionalContainer.defaultProps = {
  children: null,
  condition: false,
};

export default ConditionalContainer;
