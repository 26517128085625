import * as React from "react";

import ConditionalContainer from "components/layout/ConditionalContainer";
import TextComponent from "components/typography/Text";
import { registerSection } from "components/DynamicSection";
import PropTypes from "lib/propTypes";
import { text } from "lib/theme";

function Text({
  blok: { children, textAlign, variant },
  standalone,
  ...props
}) {
  return (
    <ConditionalContainer condition={!standalone} thin>
      <TextComponent {...{ children, textAlign, variant, ...props }} />
    </ConditionalContainer>
  );
}

Text.propTypes = {
  blok: PropTypes.shape({
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.richText]),
    textAlign: PropTypes.oneOf([`left`, `center`, `right`]),
    variant: PropTypes.oneOf(Object.keys(text)),
  }).isRequired,
  standalone: PropTypes.bool,
};

Text.defaultProps = {
  standalone: false,
};

registerSection(`Text`, Text);

export default Text;
