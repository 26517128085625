import * as React from "react";
import { Text } from "theme-ui";
import reactStringReplace from "react-string-replace";

export default (text) =>
  reactStringReplace(text, /([*†‡§¹²™®]+)/g, (match, index) => (
    <Text key={`sup-${index}`} as="sup" sx={{ fontSize: `.5em` }}>
      {match}
    </Text>
  ));
