import * as React from "react";

import ButtonComponent from "components/form/Button";
import ConditionalContainer from "components/layout/ConditionalContainer";
import { registerSection } from "components/DynamicSection";
import PropTypes from "lib/propTypes";
import { buttons } from "lib/theme";
import { Box } from "theme-ui";

function Button({ blok: { children, textAlign, to, variant }, standalone }) {
  if (!children) return null;
  return (
    <ConditionalContainer condition={!standalone} thin>
      <Box sx={{ textAlign }}>
        <ButtonComponent {...{ children, to, variant }} />
      </Box>
    </ConditionalContainer>
  );
}

Button.propTypes = {
  blok: PropTypes.shape({
    children: PropTypes.string,
    textAlign: PropTypes.oneOf([`left`, `center`, `right`]),
    to: PropTypes.link,
    variant: PropTypes.oneOf(Object.keys(buttons)),
  }).isRequired,
  standalone: PropTypes.bool,
};

Button.defaultProps = {
  standalone: false,
};

registerSection(`Button`, Button);

export default Button;
