import * as React from "react";
import { forwardRef } from "react";
import { Button as TUIButton } from "theme-ui";
import { Link as GatsbyLink } from "gatsby";

import ArrowRightIcon from "images/icons/arrow-right.svg";
import formatLink from "lib/formatLink";
import PropTypes from "lib/propTypes";

const Button = forwardRef(({ children, to, ...props }, ref) => {
  const href = formatLink(to);
  const internal = /^\/(?!\/)/.test(href);

  let linkProps;
  if (href)
    linkProps = {
      as: `a`,
      href,
      rel: `noopener noreferrer`,
      target: `_blank`,
    };
  if (internal) linkProps = { as: GatsbyLink, to: href };

  return (
    <TUIButton {...linkProps} {...props} ref={ref}>
      {children}
      {typeof children === `string` && (
        <>
          &nbsp;
          <ArrowRightIcon />
        </>
      )}
    </TUIButton>
  );
});

Button.propTypes = {
  children: PropTypes.node,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.link]),
};

Button.defaultProps = {
  children: null,
  to: null,
};

export default Button;
