import PropTypes from "prop-types";
import validateColor from "validate-color";

const asset = PropTypes.shape({
  alt: PropTypes.string,
  filename: PropTypes.string,
  id: PropTypes.number,
});

const blok = PropTypes.shape({
  _editable: PropTypes.string,
  _uid: PropTypes.string,
  component: PropTypes.string,
});

const color = (props, propName, componentName) => {
  if (!props[propName]) return null;
  if (validateColor(props[propName])) return null;
  return new Error(
    `Invalid prop \`${propName}\` supplied to` +
      ` \`${componentName}\`. Validation failed.`
  );
};

const link = PropTypes.oneOfType([
  PropTypes.oneOf([``]),
  PropTypes.shape({
    cached_url: PropTypes.string,
    email: PropTypes.string,
    linkType: PropTypes.oneOf([`email`, `story`, `url`]),
  }),
]);

const richTextShape = {
  type: PropTypes.oneOf([`doc`, `paragraph`, `text`]),
  text: PropTypes.string,
};
richTextShape.content = PropTypes.arrayOf(PropTypes.shape(richTextShape));
const richText = PropTypes.shape(richTextShape);

export default { asset, blok, color, link, richText, ...PropTypes };
