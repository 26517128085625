import * as React from "react";
import { AspectRatio as TUIAspectRatio } from "theme-ui";

import { registerSection } from "components/DynamicSection";
import PropTypes from "lib/propTypes";

function AspectRatio({ blok: { ratio } }) {
  const dimensions = ratio.split(/[/:]/);
  return <TUIAspectRatio ratio={dimensions[0] / dimensions[1]} />;
}

const isAspectRatio = (props, propName, componentName) => {
  if (!props[propName]) return null;
  if (/\s*\d+\.?\d*\s*[/:]\s*\d+\.?\d*\s*/.test(props[propName])) return null;
  return new Error(
    `Invalid prop \`${propName}\` supplied to` +
      ` \`${componentName}\`. Validation failed.`
  );
};

AspectRatio.propTypes = {
  blok: PropTypes.shape({
    ratio: isAspectRatio,
  }).isRequired,
};

registerSection(`AspectRatio`, AspectRatio);

export default AspectRatio;
