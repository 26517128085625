module.exports = {
  indicator: {
    variant: `buttons.invisible`,
    width: 20,
    height: 20,
  },
  "indicator-light": {
    variant: `buttons.indicator`,
    svg: {
      stroke: `white`,
    },
    "&:focus-visible, &:hover": {
      svg: {
        stroke: `mediumGray`,
        fill: `mediumGray`,
      },
    },
  },
  "indicator-light-active": {
    variant: `buttons.indicator`,
    svg: {
      stroke: `lightGray`,
      fill: `lightGray`,
    },
  },
  "indicator-dark": {
    variant: `buttons.indicator`,
    svg: {
      stroke: `lightGray`,
    },
    "&:focus-visible, &:hover": {
      svg: {
        stroke: `mediumGray`,
        fill: `mediumGray`,
      },
    },
  },
  "indicator-dark-active": {
    variant: `buttons.indicator`,
    svg: {
      stroke: `white`,
      fill: `white`,
    },
  },
};
