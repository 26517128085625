import * as React from "react";
import { useEffect, useRef } from "react";
import { Box, Button, Flex, IconButton } from "theme-ui";
import { useResponsiveValue } from "@theme-ui/match-media";
import { useIntersection, useToggle, useUpdateEffect } from "react-use";

import MutedIcon from "images/icons/muted.svg";
import PlayIcon from "images/icons/play.svg";
import UnmutedIcon from "images/icons/unmuted.svg";
import PropTypes from "lib/propTypes";
import sbImageUrl from "lib/sbImageUrl";
import { trackEvent } from "lib/trackingData";

function Video({
  autoPlay,
  backgroundColor,
  controls,
  loop,
  mobileSources,
  muted: defaultMuted,
  playsInline,
  poster,
  sources: desktopSources,
  sx,
  unmuteControl,
  ...props
}) {
  const videoRef = useRef(null);
  const { isIntersecting } =
    useIntersection(videoRef, { rootMargin: `0% 0% -50%` }) ?? {};

  const [videoStarted, toggleVideoStarted] = useToggle(false);
  const [muted, toggleMuted] = useToggle(defaultMuted);

  const playVideo = async () => {
    try {
      await videoRef.current.play();
    } catch (error) {
      toggleMuted(true);
      videoRef.current.muted = true;
      await videoRef.current.play();
    }
    toggleVideoStarted(true);
  };

  const resetVideo = () => {
    if (controls !== `all`) return;
    videoRef.current.load();
    toggleVideoStarted(false);
  };

  useEffect(() => {
    if (autoPlay && isIntersecting) playVideo();
  }, [autoPlay, isIntersecting]);
  useEffect(() => {
    if (!autoPlay) videoRef.current?.pause();
  }, [autoPlay]);

  const sources = useResponsiveValue([
    mobileSources.length ? mobileSources : desktopSources,
    desktopSources,
  ]);

  const sourcesString = JSON.stringify(sources);
  useUpdateEffect(() => {
    const { currentTime, ended, paused } = videoRef.current;
    videoRef.current?.load();
    videoRef.current.currentTime = currentTime;
    if (!ended && !paused) videoRef.current.play();
  }, [sourcesString]);

  const stopVideoDownload = (currentVidRef) => {
    currentVidRef.querySelectorAll(`source`).forEach((source) => {
      source.setAttribute(`src-old`, source.getAttribute(`src`));
      source.removeAttribute(`src`);
    });
    currentVidRef.load();
  };

  const resumeVideoDownload = (currentVidRef) => {
    currentVidRef.querySelectorAll(`source[src-old]`).forEach((source) => {
      source.setAttribute(`src`, source.getAttribute(`src-old`));
      source.removeAttribute(`src-old`);
    });
  };

  useEffect(() => {
    const currentVidRef = videoRef.current;
    resumeVideoDownload(currentVidRef);
    return () => stopVideoDownload(currentVidRef);
  }, []);

  const trackPlay = () => {
    if (autoPlay) return;
    trackEvent(`video-start`, { linkName: sources[0]?.alt });
  };

  const checkMuted = () => {
    toggleMuted(videoRef.current.muted);
  };

  return (
    <Flex bg={backgroundColor} sx={{ position: `relative`, ...sx }} {...props}>
      <Box
        as="video"
        ref={videoRef}
        controls={controls === `all` && videoStarted}
        poster={sbImageUrl(poster)}
        sx={{ width: `100%`, objectFit: `cover` }}
        onEnded={resetVideo}
        onPlay={trackPlay}
        onVolumeChange={checkMuted}
        {...{ loop, muted, playsInline }}
      >
        {sources.map(({ filename, id }) => {
          if (!filename) return null;
          const extension = filename.split(`.`).pop();
          return <source key={id} src={filename} type={`video/${extension}`} />;
        })}
        {sources[0]?.alt}
        <track kind="captions" />
      </Box>
      {controls === `all` && !videoStarted && (
        <Button
          variant="play"
          sx={{
            position: `absolute`,
            top: 0,
            left: 0,
            width: `100%`,
            height: `100%`,
          }}
          onClick={playVideo}
        >
          <PlayIcon />
        </Button>
      )}
      {controls === `unmute` && (
        <IconButton
          p="xxs"
          sx={{
            position: `absolute`,
            right: [5, `xxxs`, `xxs`],
            bottom: [5, `xxxs`, `xxs`],
            boxSizing: `content-box`,
            width: 24,
            height: 24,
          }}
          aria-label="Mute video"
          aria-pressed={muted}
          onClick={toggleMuted}
        >
          {muted ? <MutedIcon /> : <UnmutedIcon />}
        </IconButton>
      )}
    </Flex>
  );
}

Video.propTypes = {
  autoPlay: PropTypes.bool,
  backgroundColor: PropTypes.color,
  controls: PropTypes.oneOf([``, `all`, `unmute`]),
  loop: PropTypes.bool,
  mobileSources: PropTypes.arrayOf(PropTypes.asset),
  muted: PropTypes.bool,
  playsInline: PropTypes.bool,
  poster: PropTypes.string,
  sources: PropTypes.arrayOf(PropTypes.asset),
  sx: PropTypes.shape(),
  unmuteControl: PropTypes.bool,
};

Video.defaultProps = {
  autoPlay: false,
  backgroundColor: null,
  controls: null,
  loop: false,
  mobileSources: [],
  muted: false,
  playsInline: true,
  poster: null,
  sources: [],
  sx: null,
  unmuteControl: false,
};

export default Video;
