export const setTrackingData = ({ page, subpage }) => {
  window.digitalData = {
    page: {
      pageInfo: {
        pageId: subpage ?? page,
        destinationsURL: window?.location.href,
        pageType: page,
        attributes: {
          country: `us`,
          errorPage: false,
          language: `en-us`,
        },
      },
    },
    events: [],
  };
  // eslint-disable-next-line no-underscore-dangle
  window?._satellite?.track(`event-view-end`);
};

export const trackEvent = (type, details) =>
  // eslint-disable-next-line no-underscore-dangle
  window?._satellite?.track(type, details);
