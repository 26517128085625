module.exports = {
  default: {
    color: `inherit`,
    transition: `default`,
    "&:focus-visible, &:hover": {
      color: `mediumGray`,
    },
    ".light &:focus-visible, .light &:hover": {
      color: `lightGray`,
    },
  },
  contactButton: {
    variant: `links.default`,
    py: `xxxs`,
    px: `s`,
    border: `3px solid`,
    textDecoration: `none`,
    transitionProperty: `border-color`,
    "&:focus-visible, &:hover": {
      borderColor: `whirlpoolYellow`,
    },
    "&:active": {
      borderColor: `lightGray`,
    },
  },
  footer: {
    variant: `links.default`,
    "&:focus-visible, &:hover": {
      color: `whirlpoolYellow`,
    },
    "&:active": {
      color: `extraLightGray`,
    },
  },
};
