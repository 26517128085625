import * as React from "react";

import Column from "components/layout/Column";
import ConditionalContainer from "components/layout/ConditionalContainer";
import Row from "components/layout/Row";
import Card from "components/Card";
import { registerSection } from "components/DynamicSection";
import PropTypes from "lib/propTypes";

function CardList({ blok: { cards = [], color }, children, standalone }) {
  return (
    <ConditionalContainer condition={!standalone} thin>
      {children}
      <Row>
        {cards.map(({ _uid, buttonLink, buttonText, heading, subheading }) => (
          <Column key={_uid} span={[6, 3]}>
            <Card
              buttonTo={buttonLink}
              {...{ buttonText, color, heading, subheading }}
            />
          </Column>
        ))}
      </Row>
    </ConditionalContainer>
  );
}

CardList.propTypes = {
  blok: PropTypes.shape({
    cards: PropTypes.arrayOf(
      PropTypes.shape({
        _uid: PropTypes.string,
        buttonLink: PropTypes.link,
        buttonText: PropTypes.string,
        heading: PropTypes.string,
        subheading: PropTypes.string,
      })
    ),
    color: PropTypes.oneOf([`black`, `white`]),
  }).isRequired,
  children: PropTypes.node,
  standalone: PropTypes.bool,
};

CardList.defaultProps = {
  children: null,
  standalone: false,
};

registerSection(`CardList`, CardList);

export default CardList;
