module.exports = {
  default: {
    mt: 0,
    mb: [`xxxs`, `xs`],
    fontWeight: `book`,
    letterSpacing: `0.02em`,
    textTransform: `none`,
    p: {
      mt: 0,
      mb: [`xxxs`, `xs`],
    },
    "a:not([class])": {
      variant: `links.default`,
    },
  },
  xl: {
    variant: `text.default`,
    fontSize: [14, 26],
  },
  "xl-bold": {
    variant: `text.xl`,
    fontWeight: `bold`,
  },
  "xl-italic": {
    variant: `text.xl`,
    fontStyle: `italic`,
  },
  l: {
    variant: `text.xl`,
    fontSize: [14, 22],
  },
  "l-bold": {
    variant: `text.l`,
    fontWeight: `bold`,
  },
  "l-italic": {
    variant: `text.l`,
    fontStyle: `italic`,
  },
  m: {
    variant: `text.xl`,
    fontSize: [14, 18],
  },
  "m-bold": {
    variant: `text.m`,
    fontWeight: `bold`,
  },
  "m-italic": {
    variant: `text.m`,
    fontStyle: `italic`,
  },
  s: {
    variant: `text.xl`,
    fontSize: 14,
  },
  "s-bold": {
    variant: `text.s`,
    fontWeight: `bold`,
  },
  "s-italic": {
    variant: `text.s`,
    fontStyle: `italic`,
  },
  caption: {
    variant: `text.default`,
    fontSize: 12,
  },
  disclaimer: {
    variant: `text.default`,
    fontWeight: `normal`,
    fontSize: [10, 12],
  },
};
