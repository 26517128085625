import * as React from "react";
import { Flex } from "theme-ui";

import PropTypes from "lib/propTypes";

function ExpandingFlex({ sx, ...props }) {
  return (
    <Flex
      sx={{
        flex: `1 0 auto`,
        flexDirection: `column`,
        justifyContent: `center`,
        ...sx,
      }}
      {...props}
    />
  );
}

ExpandingFlex.propTypes = {
  sx: PropTypes.shape(),
};

ExpandingFlex.defaultProps = {
  sx: null,
};

export default ExpandingFlex;
