import * as React from "react";
import { Image as TUIImage } from "theme-ui";

import PropTypes from "lib/propTypes";
import sbImageUrl from "lib/sbImageUrl";

function Image({ ...props }) {
  const { src } = props;
  if (!src) return null;
  return <TUIImage {...props} src={sbImageUrl(src)} />;
}

Image.propTypes = {
  src: PropTypes.string,
};

Image.defaultProps = {
  src: null,
};

export default Image;
