import * as React from "react";
import { Box, Flex } from "theme-ui";

import Container from "components/layout/Container";
import Text from "components/typography/Text";
import Link from "components/Link";
import sbImageUrl from "lib/sbImageUrl";
import PropTypes from "lib/propTypes";
import useFooterBorder from "lib/useFooterBorder";
import AdditionalLinks from "./AdditionalLinks";
import BrandLogos from "./BrandLogos";

function Footer({
  additionalLinks,
  address,
  bgDesktop,
  bgMobile,
  brandLogos,
  contactButton,
  contactLink,
  disclaimer,
}) {
  const [footerBorder] = useFooterBorder();

  return (
    <Box
      variant="boxes.footer"
      sx={{
        borderTop: footerBorder ? `15px solid` : null,
        borderColor: footerBorder,
        backgroundImage: [
          `url(${sbImageUrl(bgMobile.filename)})`,
          null,
          null,
          `url(${sbImageUrl(bgDesktop.filename)})`,
        ],
      }}
    >
      <Container py="m">
        <Flex
          mb={[`xxs`, `xs`]}
          sx={{
            flexDirection: [`column`, `row`],
            alignItems: [`flex-start`, null, null, `stretch`],
          }}
        >
          <BrandLogos logos={brandLogos} />
          {contactButton && (
            <Link
              to={contactLink}
              variant="contactButton"
              my={[`xxxs`, null, null, 0]}
              sx={{ flexShrink: 0 }}
            >
              <Text variant="m">{contactButton}</Text>
            </Link>
          )}
        </Flex>
        <Text
          as="div"
          variant="caption"
          mb={[0, 0]}
          sx={{
            display: [`block`, null, null, `flex`],
            alignItems: `flex-end`,
            p: {
              mt: [`xxs`, null, null, 0],
              mb: 0,
            },
          }}
        >
          <AdditionalLinks links={additionalLinks} />
          <Box as="p" mr="xxxs">
            {disclaimer}
          </Box>
          <Box as="p">{address}</Box>
        </Text>
      </Container>
    </Box>
  );
}

Footer.propTypes = {
  additionalLinks: PropTypes.arrayOf(
    PropTypes.shape({
      _uid: PropTypes.string,
      hoverColor: PropTypes.string,
      link: PropTypes.link,
      title: PropTypes.string,
    })
  ),
  address: PropTypes.string,
  bgDesktop: PropTypes.asset,
  bgMobile: PropTypes.asset,
  brandLogos: PropTypes.arrayOf(
    PropTypes.shape({
      _uid: PropTypes.string,
      image: PropTypes.asset,
    })
  ),
  contactButton: PropTypes.string,
  contactLink: PropTypes.link,
  disclaimer: PropTypes.string,
};

Footer.defaultProps = {
  additionalLinks: [],
  address: null,
  bgDesktop: {},
  bgMobile: {},
  brandLogos: [],
  contactButton: null,
  contactLink: {},
  disclaimer: null,
};

export default Footer;
