import * as React from "react";
import { Box } from "theme-ui";
import loadable from "@loadable/component";

import { registerSection } from "components/DynamicSection";
import PropTypes from "lib/propTypes";

const CollapsibleCaseStudy = loadable(() => import(`./CollapsibleCaseStudy`));

function CaseStudy(props) {
  const {
    blok: { headerColor },
  } = props;
  return (
    <Box as="section" bg={headerColor} sx={{ minHeight: [192, 240] }}>
      <CollapsibleCaseStudy {...props} />
    </Box>
  );
}

CaseStudy.propTypes = {
  blok: PropTypes.shape({
    headerColor: PropTypes.color,
  }).isRequired,
};

registerSection(`CaseStudy`, CaseStudy);
