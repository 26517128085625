import * as React from "react";
import { Fragment, useState } from "react";
import { Grid } from "theme-ui";
import loadable from "@loadable/component";
import { find, kebabCase } from "lodash";
import { useLocation } from "@gatsbyjs/reach-router";

import { registerSection } from "components/DynamicSection";
import { setTrackingData } from "lib/trackingData";
import PropTypes from "lib/propTypes";
import BioCard from "./BioCard";

const BioModal = loadable(() => import(`./BioModal`));

function BioGrid({
  blok: { bios, buttonText, insightsDescription, logoIcon },
  pageSlug,
  pageTitle,
}) {
  const location = useLocation();
  const nameSlug = location.pathname.split(`/`)[2];
  const { _uid: defaultBio } =
    bios.find(
      ({ firstName, lastName }) =>
        kebabCase(`${firstName} ${lastName}`) === nameSlug
    ) ?? {};

  const [selectedBio, setSelectedBio] = useState(defaultBio);

  const openBio = (id) => {
    setSelectedBio(id);
    const bio = find(bios, { _uid: id });
    const name = `${bio.firstName} ${bio.lastName}`;
    const path = `/${pageSlug}/${kebabCase(name)}/`;
    window?.history?.pushState({}, ``, path);
    setTrackingData({
      page: pageTitle,
      subpage: name,
      url: window?.location.href,
    });
  };

  const closeBio = () => {
    setSelectedBio(null);
    const path = `/${pageSlug}/`;
    window?.history?.pushState({}, ``, path);
    setTrackingData({ page: pageTitle, url: window?.location.href });
  };

  return (
    <section>
      <Grid
        gap={0}
        columns="repeat(auto-fit, minmax(20rem, 1fr))"
        sx={{ bg: `darkGray` }}
      >
        {bios.map(({ _uid: id, ...bio }) => (
          <Fragment key={id}>
            <BioCard
              buttonText={buttonText}
              onClick={() => openBio(id)}
              {...bio}
            />
            <BioModal
              close={closeBio}
              insightsDescription={insightsDescription}
              logoIcon={logoIcon}
              visible={id === selectedBio}
              {...bio}
            />
          </Fragment>
        ))}
      </Grid>
    </section>
  );
}

BioGrid.propTypes = {
  blok: PropTypes.shape({
    bios: PropTypes.arrayOf(PropTypes.blok),
    buttonText: PropTypes.string,
    insightsDescription: PropTypes.string,
    logoIcon: PropTypes.asset,
  }).isRequired,
  pageSlug: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
};

registerSection(`BioGrid`, BioGrid);

export default BioGrid;
