const colors = require(`./colors`);
const space = require(`./space`);

module.exports = {
  borderWidths: space,
  colors: {
    ...colors,
    background: `#f5f2ec`,
    text: colors.black,
  },
  fonts: {
    body: `nobel, sans-serif`,
    heading: `nobel, sans-serif`,
  },
  fontWeights: {
    body: 300,
    heading: 700,
    book: 300,
    normal: 400,
    bold: 700,
  },
  lineHeights: {
    body: 1.35,
    heading: 1.25,
  },
  sizes: {
    container: 1160,
    thinContainer: 779,
  },
  space,
  transitions: {
    default: `all 300ms cubic-bezier(0.4, 0.0, 0.2, 1)`,
    expandingHeader: `all 300ms cubic-bezier(0, 0, 0.58, 1)`,
  },
};
