import * as React from "react";
import { css } from "@emotion/react";

import SpacerComponent from "components/layout/Spacer";
import { registerSection } from "components/DynamicSection";
import { space } from "lib/theme";
import PropTypes from "lib/propTypes";

function Spacer({ blok: { backgroundColor, customCss, size } }) {
  return (
    <SpacerComponent size={size} bg={backgroundColor} css={css(customCss)} />
  );
}

Spacer.propTypes = {
  blok: PropTypes.shape({
    backgroundColor: PropTypes.color,
    customCss: PropTypes.string,
    size: PropTypes.oneOf(Object.keys(space)),
  }).isRequired,
};

registerSection(`Spacer`, Spacer);

export default Spacer;
