module.exports = {
  root: {
    fontFamily: `body`,
    fontSize: 16,
    lineHeight: `body`,
    fontWeight: `body`,
  },
  hr: {
    borderColor: `black`,
  },
};
