import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Column from "components/layout/Column";
import Row from "components/layout/Row";
import CardList from "components/sections/CardList";
import Heading from "components/typography/Heading";
import Card from "components/Card";
import { registerSection } from "components/DynamicSection";
import PropTypes from "lib/propTypes";
import textareaToArray from "lib/textareaToArray";

// const replaceLocations = (prevLocation, { from, to }) =>
//   prevLocation.toLowerCase().includes(from.toLowerCase()) ? to : prevLocation;

function JobPostings({
  blok: {
    buttonText: jobButtonText,
    color,
    footing,
    locationReplacements,
    matchText,
    ...blok
  },
  standalone,
}) {
  // const { allJobPostings } = useStaticQuery(graphql`
  //   query JobPostingsQuery {
  //     allJobPostings(filter: { id: { ne: "dummy" } }) {
  //       edges {
  //         node {
  //           id
  //           jobReqId
  //           jobRequisition {
  //             jobReqLocale {
  //               results {
  //                 externalJobDescription
  //                 externalTitle
  //               }
  //             }
  //             location
  //           }
  //         }
  //       }
  //     }
  //   }
  // `);

  // const jobPostings = allJobPostings.edges
  //   .map(({ node: { id, jobReqId, jobRequisition } }) => ({
  //     id,
  //     description:
  //       jobRequisition.jobReqLocale.results[0].externalJobDescription,
  //     link: `https://jobs.whirlpool.com/careers?query=${jobReqId}`,
  //     location: jobRequisition.location,
  //     title: jobRequisition.jobReqLocale.results[0].externalTitle,
  //   }))
  //   .filter(({ description }) => {
  //     const matchList = textareaToArray(matchText);
  //     if (!matchList.length) return true;
  //     return matchList.some((text) => description.includes(text));
  //   })
  //   .map(({ location, ...posting }) => ({
  //     ...posting,
  //     location: locationReplacements.reduce(
  //       replaceLocations,
  //       location.replace(/\s+\(.*\)/, ``)
  //     ),
  //   }));

  return (
    <CardList {...{ blok, color, standalone }}>
      {/* <Row>
        {jobPostings.map(({ id, link, location, title }) => (
          <Column key={id} span={[6, 3]}>
            <Card
              heading={title}
              subheading={location}
              buttonTo={link}
              buttonText={jobButtonText}
              color={color}
            />
          </Column>
        ))}
      </Row> */}
      <Heading variant="h3" sx={{ fontWeight: `bold` }}>
        {footing}
      </Heading>
    </CardList>
  );
}

JobPostings.propTypes = {
  blok: PropTypes.shape({
    buttonText: PropTypes.string,
    color: PropTypes.oneOf([`black`, `white`]),
    footing: PropTypes.richText,
    locationReplacements: PropTypes.arrayOf(
      PropTypes.shape({
        from: PropTypes.string,
        to: PropTypes.string,
      })
    ),
    matchText: PropTypes.string,
  }).isRequired,
  standalone: PropTypes.bool,
};

JobPostings.defaultProps = {
  standalone: false,
};

registerSection(`JobPostings`, JobPostings);

export default JobPostings;
