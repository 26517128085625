import * as React from "react";

import ExpandingFlex from "./ExpandingFlex";

export default function Wrapper(props) {
  return (
    <ExpandingFlex
      as="section"
      py={[`m`, `l`]}
      bg="whirlpoolYellow"
      {...props}
    />
  );
}
