import * as React from "react";
import { Flex } from "theme-ui";

import Image from "components/media/Image";
import Link from "components/Link";
import PropTypes from "lib/propTypes";

function BrandLogos({ logos }) {
  return (
    <Flex
      as="ul"
      variant="lists.unstyled"
      sx={{ flexWrap: [`wrap`, null, null, `nowrap`] }}
    >
      {logos.map(({ _uid, image, link }) => (
        <Flex key={_uid} as="li" mr={[`xxs`, `xs`]}>
          <Link
            to={link}
            sx={{
              display: `flex`,
              alignItems: `center`,
              minHeight: 48,
            }}
          >
            <Image
              alt={image.alt}
              src={image.filename}
              width="75"
              height="30"
              sx={{ width: `auto`, height: [30, 38] }}
            />
          </Link>
        </Flex>
      ))}
    </Flex>
  );
}

BrandLogos.propTypes = {
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      _uid: PropTypes.string,
      image: PropTypes.asset,
      link: PropTypes.link,
    })
  ),
};

BrandLogos.defaultProps = {
  logos: [],
};

export default BrandLogos;
