module.exports = {
  play: {
    variant: `buttons.invisible`,
    svg: {
      width: [40, 80],
      height: [40, 80],
    },
    "circle, path": {
      transition: `default`,
      transformOrigin: `center`,
    },
    "&:focus-visible, &:hover": {
      circle: {
        fillOpacity: 0.85,
        strokeWidth: 3,
      },
      path: {
        fill: `black`,
        transform: `scale(1.15)`,
      },
    },
    "&:active": {
      "circle, path": {
        fill: `extraLightGray`,
      },
    },
  },
};
