/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { sbEditable } from "@storyblok/storyblok-editable";
import { Global } from "@emotion/react";

import Footer from "components/global/Footer/Footer";
import Header from "components/global/Header/Header";
import ExpandingFlex from "components/layout/ExpandingFlex";
import "components/sections/Sections";
import PropTypes from "lib/propTypes";

function Layout({ children, globalStory }) {
  const data = useStaticQuery(graphql`
    query GlobalQuery {
      site {
        siteMetadata {
          title
        }
      }
      storyblokEntry(full_slug: { eq: "global" }) {
        content
      }
    }
  `);

  let globalContent = {};
  if (globalStory) globalContent = globalStory.content;
  else if (data.storyblokEntry)
    globalContent = JSON.parse(data.storyblokEntry.content);

  return (
    <ExpandingFlex {...(globalStory ? sbEditable(globalContent) : null)}>
      <Global
        styles={{
          "html, body": {
            height: `100%`,
          },
          "body, #___gatsby, #gatsby-focus-wrapper": {
            flex: 1,
            display: `flex`,
            flexDirection: `column`,
          },
        }}
      />
      <Header
        logo={globalContent.logo}
        logoIcon={globalContent.logoIcon}
        logoText={globalContent.logoText}
        navigation={globalContent.navigation}
        siteTitle={data.site.siteMetadata?.title || `Title`}
      />
      <ExpandingFlex as="main" sx={{ overflow: `hidden` }}>
        {children}
      </ExpandingFlex>
      <Footer
        additionalLinks={globalContent.additionalLinks}
        address={globalContent.address}
        bgDesktop={globalContent.footerBgDesktop}
        bgMobile={globalContent.footerBgMobile}
        brandLogos={globalContent.brandLogos}
        contactButton={globalContent.contactButton}
        contactLink={globalContent.contactLink}
        disclaimer={globalContent.disclaimer}
      />
    </ExpandingFlex>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
  globalStory: PropTypes.shape({
    content: PropTypes.shape({
      additionalLinks: PropTypes.arrayOf(
        PropTypes.shape({
          _uid: PropTypes.string,
          link: PropTypes.link,
          title: PropTypes.string,
        })
      ),
      brandLogos: PropTypes.arrayOf(
        PropTypes.shape({
          _uid: PropTypes.string,
          image: PropTypes.asset,
          smWidth: PropTypes.string,
          mdWidth: PropTypes.string,
          lgWidth: PropTypes.string,
        })
      ),
      disclaimer: PropTypes.richText,
      address: PropTypes.string,
      footerBgDesktop: PropTypes.asset,
      footerBgMobile: PropTypes.asset,
      logo: PropTypes.asset,
      logoIcon: PropTypes.asset,
      logoText: PropTypes.string,
      navigation: PropTypes.arrayOf(PropTypes.shape()),
    }),
  }),
};

Layout.defaultProps = {
  children: null,
  globalStory: null,
};

export default Layout;
