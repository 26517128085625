import * as React from "react";
import { Text as TUIText } from "theme-ui";
import { render } from "storyblok-rich-text-react-renderer";

import formatNbsp from "lib/formatNbsp";
import supSymbols from "lib/supSymbols";
import theme from "lib/theme";
import PropTypes from "lib/propTypes";
import wowKerning from "lib/wowKerning";

const textResolver = (text) =>
  wowKerning(formatNbsp(supSymbols(text)), `-.05em`);

function Text({ children, sx, textAlign, variant, ...props }) {
  if (!children) return null;
  if (
    children.content &&
    children.content.length === 1 &&
    !children.content[0].content
  )
    return null;
  return (
    <TUIText variant={`text.${variant}`} sx={{ textAlign, ...sx }} {...props}>
      {children.type === `doc`
        ? render(children, { textResolver })
        : textResolver(children)}
    </TUIText>
  );
}

Text.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.richText]),
  sx: PropTypes.shape(),
  textAlign: PropTypes.oneOf([`left`, `center`, `right`]),
  variant: PropTypes.oneOf(Object.keys(theme.text)),
};

Text.defaultProps = {
  children: null,
  sx: null,
  textAlign: `left`,
  variant: `xl`,
};

export default Text;
