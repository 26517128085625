import * as React from "react";

import ImageComponent from "components/media/Image";
import { registerSection } from "components/DynamicSection";
import PropTypes from "lib/propTypes";
import { Flex } from "theme-ui";

function Image({ blok: { image } }) {
  return (
    <Flex sx={{ justifyContent: `center` }}>
      <ImageComponent alt={image.alt} src={image.filename} />
    </Flex>
  );
}

Image.propTypes = {
  blok: PropTypes.shape({
    image: PropTypes.asset,
  }).isRequired,
};

registerSection(`Image`, Image);

export default Image;
