import * as React from "react";

import ConditionalContainer from "components/layout/ConditionalContainer";
import HeadingComponent from "components/typography/Heading";
import { registerSection } from "components/DynamicSection";
import PropTypes from "lib/propTypes";
import { headings } from "lib/theme";

function Heading({
  blok: { as, children, textAlign, variant },
  standalone,
  ...props
}) {
  return (
    <ConditionalContainer condition={!standalone} thin>
      <HeadingComponent {...{ as, children, textAlign, variant, ...props }} />
    </ConditionalContainer>
  );
}

Heading.propTypes = {
  blok: PropTypes.shape({
    as: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.richText]),
    textAlign: PropTypes.oneOf([`left`, `center`, `right`]),
    variant: PropTypes.oneOf(Object.keys(headings)),
  }).isRequired,
  standalone: PropTypes.bool,
};

Heading.defaultProps = {
  standalone: false,
};

registerSection(`Heading`, Heading);

export default Heading;
