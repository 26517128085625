import * as React from "react";
import { Link as TUILink } from "theme-ui";
import { Link as GatsbyLink } from "gatsby";

import formatLink from "lib/formatLink";
import PropTypes from "lib/propTypes";

function Link({ to, ...props }) {
  const href = formatLink(to);
  const internal = /^\/(?!\/)/.test(href);

  let linkProps = { href, rel: `noopener noreferrer`, target: `_blank` };
  if (internal) linkProps = { as: GatsbyLink, to: href };

  return <TUILink {...linkProps} {...props} />;
}

Link.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.link]),
};

Link.defaultProps = {
  to: null,
};

export default Link;
