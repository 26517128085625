import * as React from "react";
import { Box } from "theme-ui";

import PropTypes from "lib/propTypes";

function Column({ span, ...props }) {
  const values = Array.isArray(span) ? span : [span];
  const gridColumnEnd = values.map((value) => `span ${value}`);
  return <Box sx={{ gridColumnEnd }} {...props} />;
}

const isNumberString = (props, propName, componentName) => {
  if (!props[propName] || +props[propName]) return null;
  return new Error(
    `Invalid prop \`${propName}\` supplied to` +
      ` \`${componentName}\`. Validation failed.`
  );
};

Column.propTypes = {
  span: PropTypes.oneOfType([
    isNumberString,
    PropTypes.arrayOf(isNumberString),
  ]),
};

Column.defaultProps = {
  span: `1 / -1`,
};

export default Column;
