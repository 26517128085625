module.exports = {
  "dark-primary": {
    variant: `buttons.brand-primary`,
    borderColor: `nearBlack`,
    bg: `nearBlack`,
    color: `extraLightGray`,
    "&:focus-visible, &:hover": {
      borderColor: `black`,
      bg: `black`,
      color: `white`,
    },
    "&:active": {
      borderColor: `darkGray`,
      bg: `darkGray`,
      color: `lightGray`,
    },
  },
  "dark-secondary": {
    variant: `buttons.brand-secondary`,
    color: `nearBlack`,
    "&:focus-visible, &:hover": {
      color: `black`,
    },
    "&:active": {
      color: `gray`,
    },
  },
  "dark-tertiary": {
    variant: `buttons.brand-tertiary`,
    color: `nearBlack`,
    "&:focus-visible, &:hover": {
      color: `black`,
    },
    "&:active": {
      color: `gray`,
    },
  },
};
