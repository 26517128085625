const arrow = require(`./arrow`);
const backToTop = require(`./backToTop`);
const brand = require(`./brand`);
const dark = require(`./dark`);
const indicator = require(`./indicator`);
const light = require(`./light`);
const play = require(`./play`);

module.exports = {
  primary: {
    variant: `buttons.brand-primary`,
  },
  icon: {
    "&:not(:disabled)": {
      cursor: `pointer`,
    },
  },
  invisible: {
    display: `inline-flex`,
    justifyContent: `center`,
    alignItems: `center`,
    p: 0,
    border: 0,
    borderRadius: 0,
    bg: `transparent`,
    fontFamily: `body`,
    transition: `default`,
    "&:focus-visible, &:hover": {
      bg: `transparent`,
    },
    "&:not(:disabled)": {
      cursor: `pointer`,
    },
  },
  ...brand,
  ...light,
  ...dark,
  ...arrow,
  ...indicator,
  ...play,
  ...backToTop,
};
