import * as React from "react";
import { Flex, useThemeUI } from "theme-ui";

import Button from "components/form/Button";
import Image from "components/media/Image";
import Heading from "components/typography/Heading";
import Text from "components/typography/Text";
import bioDefaultImage from "images/bio-default.png";
import sbImageUrl from "lib/sbImageUrl";
import PropTypes from "lib/propTypes";

function BioCard({
  buttonText,
  firstName,
  image,
  imageMobile,
  lastName,
  onClick,
  title,
}) {
  const { theme } = useThemeUI();
  return (
    <Button
      variant="invisible"
      sx={{
        position: `relative`,
        overflow: `hidden`,
        textAlign: `left`,
        "&:focus-visible": {
          zIndex: 1,
        },
      }}
      onClick={onClick}
    >
      <picture>
        <source
          media={`(min-width: ${theme.breakpoints[0]})`}
          srcSet={sbImageUrl(image.filename) || bioDefaultImage}
        />
        <Image
          alt={image.alt}
          src={sbImageUrl(imageMobile.filename) || bioDefaultImage}
          width="320"
          height="320"
          sx={{
            display: `block`,
            width: `100%`,
            filter: `grayscale(100%)`,
            transition: `default`,
            "button:is(:focus-visible, :hover) &": {
              filter: `none`,
              transform: `scale(1.1)`,
            },
          }}
        />
      </picture>
      <Flex
        sx={{
          flexDirection: `column`,
          justifyContent: `space-between`,
          position: `absolute`,
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          p: [`xxxs`, `xs`],
          backgroundImage: `linear-gradient(24.26deg, rgba(0, 0, 0, 0.45) -17.94%, transparent 39.65%), linear-gradient(131.32deg, black -9.57%, transparent 86.94%)`,
        }}
      >
        <div>
          <Heading as="h2" variant="h5" mb={[0, `xxxs`]}>
            {firstName} {lastName}
          </Heading>
          {title && (
            <Text
              as="p"
              variant="m"
              mb={[0, `xxxs`]}
              sx={{ width: [`33.33%`, `50%`] }}
            >
              {title}
            </Text>
          )}
        </div>
        <Button
          as="p"
          variant="buttons.light-tertiary"
          mb={-12}
          sx={{
            alignSelf: `flex-start`,
            "button:is(:focus-visible, :hover) &": {
              color: `white`,
              svg: {
                transform: `translateX(6px)`,
              },
            },
            "button:active &": {
              color: `whirlpoolYellow`,
            },
          }}
        >
          {buttonText}
        </Button>
      </Flex>
    </Button>
  );
}

BioCard.propTypes = {
  buttonText: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  image: PropTypes.asset,
  imageMobile: PropTypes.asset,
  lastName: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  title: PropTypes.string,
};

BioCard.defaultProps = {
  image: {},
  imageMobile: {},
  onClick: () => {},
  title: null,
};

export default BioCard;
