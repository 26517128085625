module.exports = {
  backToTopDark: {
    variant: `buttons.invisible`,
    margin: -20,
    padding: `2px 1px 0`,
    transition: `none`,
    "*": {
      transition: `default`,
    },
    "&:focus-visible, &:hover": {
      svg: {
        transform: `scale(1.12)`,
      },
      circle: {
        fill: `black`,
      },
      path: {
        fill: `white`,
      },
    },
    "&:active": {
      svg: {
        transform: `scale(.9)`,
      },
      circle: {
        stroke: `nearBlack`,
        fill: `nearBlack`,
      },
      path: {
        fill: `lightGray`,
      },
    },
  },
  backToTopLight: {
    variant: `buttons.backToTopDark`,
    circle: {
      stroke: `white`,
    },
    path: {
      fill: `white`,
    },
    "&:focus-visible, &:hover": {
      svg: {
        transform: `scale(1.12)`,
      },
      circle: {
        fill: `white`,
      },
      path: {
        fill: `black`,
      },
    },
    "&:active": {
      svg: {
        transform: `scale(.9)`,
      },
      circle: {
        stroke: `extraLightGray`,
        fill: `extraLightGray`,
      },
      path: {
        fill: `darkGray`,
      },
    },
  },
};
