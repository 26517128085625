import * as React from "react";
import { useRef } from "react";
import { Box, Flex } from "theme-ui";
import { useResponsiveValue } from "@theme-ui/match-media";

import Button from "components/form/Button";
import Image from "components/media/Image";
import CloseIcon from "images/icons/close.svg";
import MenuIcon from "images/icons/menu.svg";
import PropTypes from "lib/propTypes";
import sbImageUrl from "lib/sbImageUrl";

function Bar({
  close,
  iconMode,
  logo,
  logoIcon,
  logoText,
  sx,
  toggle,
  ...props
}) {
  const buttonRef = useRef(null);
  const currentLogo = useResponsiveValue([logoIcon, logo]);

  const ButtonIcon = close ? CloseIcon : MenuIcon;

  return (
    <Flex
      sx={{
        flexShrink: 0,
        position: `relative`,
        justifyContent: `center`,
        alignItems: `center`,
        height: [80, 100],
        ...sx,
      }}
      {...props}
    >
      {currentLogo?.filename && (
        <Button
          variant="invisible"
          to="/"
          aria-label={logoText}
          sx={{
            position: [`absolute`, `static`],
            left: `xxs`,
            margin: `auto`,
          }}
        >
          <Image
            alt={currentLogo.alt}
            src={sbImageUrl(currentLogo.filename)}
            width="50"
            height="50"
            sx={{
              width: [50, `auto`],
              height: [50, 60],
            }}
          />
        </Button>
      )}
      <Button
        ref={buttonRef}
        variant="invisible"
        aria-label={close ? `Close` : `Menu`}
        ml="auto"
        sx={{
          position: `absolute`,
          right: [`xxs`, `s`],
          margin: `auto`,
          width: 50,
          height: 50,
        }}
        onClick={close ?? toggle}
      >
        <Box
          as={ButtonIcon}
          sx={{
            width: 40,
            fill: iconMode === `light` ? `white` : `black`,
          }}
        />
      </Button>
    </Flex>
  );
}

Bar.propTypes = {
  close: PropTypes.func,
  iconMode: PropTypes.oneOf([`dark`, `light`]),
  logo: PropTypes.asset,
  logoIcon: PropTypes.asset,
  logoText: PropTypes.string,
  sx: PropTypes.shape(),
  toggle: PropTypes.func,
};

Bar.defaultProps = {
  close: null,
  iconMode: `light`,
  logo: null,
  logoIcon: null,
  logoText: null,
  sx: null,
  toggle: null,
};

export default Bar;
