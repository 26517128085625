import * as React from "react";
import { Container as TUIContainer } from "theme-ui";

import PropTypes from "lib/propTypes";

export default function Container({ thin, ...props }) {
  return <TUIContainer variant={thin ? `thin` : `container`} {...props} />;
}

Container.propTypes = {
  thin: PropTypes.bool,
};

Container.defaultProps = {
  thin: false,
};
