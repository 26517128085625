import * as React from "react";
import { Box } from "theme-ui";

import PropTypes from "lib/propTypes";

function Spacer({ size, ...props }) {
  return <Box pt={size} {...props} />;
}

Spacer.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

Spacer.defaultProps = {
  size: `m`,
};

export default Spacer;
