import * as React from "react";
import { Heading as TUIHeading } from "theme-ui";
import { render } from "storyblok-rich-text-react-renderer";

import avoidOrphan from "lib/avoidOrphan";
import formatNbsp from "lib/formatNbsp";
import supSymbols from "lib/supSymbols";
import { headings } from "lib/theme";
import PropTypes from "lib/propTypes";
import wowKerning from "lib/wowKerning";

const textResolver = (text) =>
  wowKerning(formatNbsp(supSymbols(text)), `-.1em`);

function Heading({ children, sx, textAlign, variant, ...props }) {
  if (!children) return null;
  if (
    children.content &&
    children.content.length === 1 &&
    !children.content[0].content
  )
    return null;
  return (
    <TUIHeading
      variant={`headings.${variant}`}
      sx={{ textAlign, ...sx }}
      {...props}
    >
      {children.type === `doc`
        ? render(children, { textResolver })
        : wowKerning(formatNbsp(avoidOrphan(supSymbols(children))), `-.1em`)}
    </TUIHeading>
  );
}

Heading.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.richText]),
  sx: PropTypes.shape(),
  textAlign: PropTypes.oneOf([`left`, `center`, `right`]),
  variant: PropTypes.oneOf(Object.keys(headings)),
};

Heading.defaultProps = {
  children: null,
  sx: null,
  textAlign: `left`,
  variant: `h1`,
};

export default Heading;
