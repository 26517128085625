import * as React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useEvent, useMedia } from "react-use";

import Video from "components/media/Video";
import { registerSection } from "components/DynamicSection";
import PropTypes from "lib/propTypes";

function AnimatedLogo({
  blok: { backgroundColor, sourcesHorizontal, sourcesVertical },
}) {
  const isLandscape = useMedia(`(orientation: landscape)`);

  const getInitialHeight = () =>
    document.documentElement?.clientHeight || window.innerHeight;
  const [minMobileHeight, setMinMobileHeight] = useState(getInitialHeight());
  useEvent(`resize`, () => setMinMobileHeight(getInitialHeight()));

  return (
    <>
      {backgroundColor && (
        <Helmet meta={[{ name: `theme-color`, content: backgroundColor }]} />
      )}
      <Video
        autoPlay
        controls="unmute"
        sources={isLandscape ? sourcesHorizontal : sourcesVertical}
        bg={backgroundColor}
        sx={{
          flex: `1 0 auto`,
          flexDirection: `column`,
          height: isLandscape ? `56.25vw` : minMobileHeight,
          video: {
            position: `absolute`,
            height: `100%`,
          },
        }}
      />
    </>
  );
}

AnimatedLogo.propTypes = {
  blok: PropTypes.shape({
    backgroundColor: PropTypes.color,
    sourcesHorizontal: PropTypes.arrayOf(PropTypes.asset),
    sourcesVertical: PropTypes.arrayOf(PropTypes.asset),
  }).isRequired,
};

registerSection(`AnimatedLogo`, AnimatedLogo);

export default AnimatedLogo;
