module.exports = {
  default: {
    mt: 0,
    mb: [`xxxs`, `xs`],
    fontWeight: `normal`,
    textTransform: `none`,
    p: {
      mt: 0,
      mb: [`xxxs`, `xs`],
    },
    "a:not([class])": {
      variant: `links.default`,
    },
  },
  h1: {
    variant: `headings.default`,
    fontWeight: `bold`,
    fontSize: [38, 54],
    letterSpacing: `-0.03em`,
  },
  "h1-alt": {
    variant: `headings.h1`,
    fontWeight: `bold`,
    letterSpacing: `-0.05em`,
  },
  "h2-bold": {
    variant: `headings.h1`,
    fontSize: [34, 48],
  },
  "h2-reg": {
    variant: `headings.h2-bold`,
    fontWeight: `normal`,
  },
  "h2-book": {
    variant: `headings.h2-bold`,
    fontWeight: `book`,
  },
  h3: {
    variant: `headings.default`,
    fontSize: [32, 36],
  },
  h4: {
    variant: `headings.default`,
    fontSize: [28, 32],
  },
  h5: {
    variant: `headings.default`,
    fontSize: [24, 28],
    letterSpacing: [`-0.02em`],
  },
  h6: {
    variant: `headings.default`,
    fontSize: [20, 24],
  },
  overline1: {
    variant: `headings.default`,
    fontWeight: `bold`,
    fontSize: [14, 16],
    textTransform: `uppercase`,
  },
  overline2: {
    variant: `headings.overline1`,
    fontSize: [12, 14],
  },
  subtitle: {
    variant: `headings.default`,
    fontWeight: `book`,
    fontSize: [20, 22],
  },
};
