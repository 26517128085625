import * as React from "react";
import { useEffect, useState } from "react";
import { sample } from "lodash";

import DynamicSection, { registerSection } from "components/DynamicSection";
import PropTypes from "lib/propTypes";

function RandomBlock({
  blok: {
    body,
    default: [defaultBlok],
  },
}) {
  const [selectedBlok, setSelectedBlok] = useState(defaultBlok);
  useEffect(() => setSelectedBlok(sample(body)), [body]);
  return <DynamicSection blok={selectedBlok} />;
}

RandomBlock.propTypes = {
  blok: PropTypes.shape({
    body: PropTypes.arrayOf(PropTypes.blok),
    default: PropTypes.arrayOf(PropTypes.blok),
  }).isRequired,
};

registerSection(`RandomBlock`, RandomBlock);

export default RandomBlock;
