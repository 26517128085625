module.exports = {
  jennAir: `#000000`,
  maytagDarkBlue: `#002e5f`,
  maytagLightBlue: `#005abb`,
  amanaBabyBlue: `#bbd8ea`,
  amanaRed: `#f32434`,
  kitchenAid: `#c41230`,
  whirlpoolYellow: `#eebb11`,
  whirlpoolGray: `#f2f2f2`,
  secondaryBlue1: `#002e5f`,
  secondaryBlue2: `#012bc3`,
  secondaryBlue3: `#005abb`,
  secondaryBlue4: `#0478d6`,
  secondaryPurple1: `#6961a6`,
  secondaryGreen1: `#1b5729`,
  secondaryGreen2: `#1e8824`,
  secondaryTeal1: `#1e8388`,
  secondaryTeal2: `#006f95`,
  secondaryPurple2: `#5501c3`,
  secondaryBrick: `#ba5757`,
  secondaryPink1: `#c14683`,
  secondaryPink2: `#db2c7a`,
  secondaryFuchsia1: `#b746c1`,
  secondaryFuchsia2: `#891155`,
  secondaryBrown: `#7b3912`,
  secondaryOrange1: `#ba5920`,
  secondaryOrange2: `#d64206`,
  secondaryBurgundy: `#942828`,
  secondaryRed: `#c41230`,
  insightSalmon: `#f26666`,
  insightTurquoise: `#006f95`,
  insightGreen: `#66cc99`,
  insightYellow: `#eeb111`,
  black: `#000000`,
  nearBlack: `#282828`,
  darkGray: `#3e3e3e`,
  mediumGray: `#686868`,
  gray: `#767676`,
  lightGray: `#d1d3d4`,
  extraLightGray: `#eaeaeb`,
  white: `#ffffff`,
};
