module.exports = {
  "brand-primary": {
    variant: `buttons.invisible`,
    px: 20,
    py: 10,
    border: `2px solid`,
    borderColor: `nearBlack`,
    minHeight: 48,
    bg: `nearBlack`,
    color: `white`,
    fontSize: 14,
    fontWeight: `bold`,
    textTransform: `uppercase`,
    textAlign: `center`,
    svg: {
      mr: 5,
      transition: `default`,
      transitionProperty: `transform`,
    },
    "&:focus-visible, &:hover": {
      borderColor: `white`,
      bg: `white`,
      color: `black`,
    },
    "&:is(:focus-visible, :hover) svg": {
      transform: `translateX(5px)`,
    },
    "&:active": {
      borderColor: `lightGray`,
      bg: `lightGray`,
      color: `mediumGray`,
    },
  },
  "brand-secondary": {
    variant: `buttons.brand-primary`,
    bg: `transparent`,
    borderColor: `currentColor`,
    color: `nearBlack`,
    "&:focus-visible, &:hover": {
      color: `black`,
    },
    "&:active": {
      color: `mediumGray`,
    },
  },
  "brand-tertiary": {
    variant: `buttons.brand-secondary`,
    mx: -22,
    borderColor: `transparent`,
    "&:focus-visible, &:hover": {
      color: `darkGray`,
    },
    "&:active": {
      color: `mediumGray`,
    },
  },
};
